<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="客户管理">
      <table-page :config="config1" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="机构管理">
      <table-page :config="config2" />
    </a-tab-pane>
    <a-tab-pane key="3" tab="登录日志">
      <table-page :config="config3" />
    </a-tab-pane>
    <a-tab-pane key="4" tab="CRM登录日志">
      <table-page :config="config4" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { loginStatus } from '@/utils/public';

export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '1',
      config1: {
        dataUrl: `/operationLog/list?menuCode=0`,
        filter: {
          initUrl: '/operationLog/listFunction?menuCode=0',
          controls: [
            {
              key: 'operationFunction',
              label: '功能',
              type: 'souSelect',
              width: '260',
              config: {
                options: "functionList"
              }
            },
            {
              key: 'operatorId',
              label: '操作人',
              type: 'souSelect',
              width: '260',
              config: {
                options: "operatorList"
              }
            },
            {
              key: 'objectId',
              label: '对象ID',
              type: 'text'
            },
            {
              key: 'objectName',
              label: '客户姓名',
              type: 'text'
            },
            {
              key: 'time',
              label: '操作时间',
              type: 'dateRange'
            },
          ]
        },
        columns: [
          {
            key: 'operationFunction',
            title: '功能',
            width: '130px',
          },
          {
            key: 'operatorName',
            title: '操作人',
            width: '130px',
          },
          {
            key: 'objectName',
            title: '客户姓名',
            width: '130px',
          },
          {
            key: 'operationRecord',
            title: '记录',
            width: '250px',
            ellipsis: true
          },
          {
            key: 'operationTime',
            title: '操作时间',
            width: '130px',
            type: 'date',
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
      config2: {
        dataUrl: `/operationLog/list?menuCode=100`,
        filter: {
          initUrl: '/operationLog/listFunction?menuCode=100',
          controls: [
            {
              key: 'operationFunction',
              label: '功能',
              type: 'souSelect',
              width: '260',
              config: {
                options: "functionList"
              }
            },
            {
              key: 'operatorId',
              label: '操作人',
              type: 'souSelect',
              width: '260',
              config: {
                options: "operatorList"
              }
            },
            {
              key: 'objectId',
              label: '对象ID',
              type: 'text'
            },
            {
              key: 'objectName',
              label: '机构名称',
              type: 'text'
            },
            {
              key: 'time',
              label: '操作时间',
              type: 'dateRange'
            },
          ]
        },
        columns: [
          {
            key: 'operationFunction',
            title: '功能',
            width: '130px',
          },
          {
            key: 'operatorName',
            title: '操作人',
            width: '130px',
          },
          {
            key: 'objectName',
            title: '机构名称',
            width: '130px',
          },
          {
            key: 'operationRecord',
            title: '记录',
            width: '250px',
            ellipsis: true
          },
          {
            key: 'operationTime',
            title: '操作时间',
            width: '130px',
            type: 'date',
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
      config3: {
        dataUrl: `/login/log/list`,
        filter: {
          // initUrl: '/operationLog/listFunction?menuCode=0',
          controls: [
            {
              key: 'time',
              label: '登录时间',
              type: 'dateRange'
            },
            {
              key: 'loginStatus',
              label: '登陆状态',
              type: 'souSelect',
              width: '260',
              config: {
                options: loginStatus
              }
            }
          ]
        },
        columns: [
          {
            key: 'createdDate',
            title: '登录时间',
            width: '130px',
            type: 'date',
          },
          {
            key: 'city',
            title: '登录城市',
            width: '130px',
          },
          {
            key: 'ip',
            title: '登录IP',
            width: '130px',
          },
          {
            key: 'loginStatus',
            title: '登录状态',
            width: '130px',
            type: 'textEnum',
            config: {
              constants: {
                success: '登录成功',
                failed: '登录失败',
              }
            }
          },
          {
            key: 'username',
            title: '登陆账号',
            width: '130px',
          },
          {
            key: 'mark',
            title: '标记',
            width: '180px',
            ellipsis: true
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
      config4: {
        dataUrl: `/login/log/getCrmlist`,
        filter: {
          // initUrl: '/operationLog/listFunction?menuCode=0',
          controls: [
            {
              key: 'time',
              label: '登录时间',
              type: 'dateRange'
            },
            {
              key: 'loginStatus',
              label: '登陆状态',
              type: 'souSelect',
              width: '260',
              config: {
                options: loginStatus
              }
            }
          ]
        },
        columns: [
          {
            key: 'createdDate',
            title: '登录时间',
            width: '130px',
            type: 'date',
          },
          {
            key: 'city',
            title: '登录城市',
            width: '130px',
          },
          {
            key: 'ip',
            title: '登录IP',
            width: '130px',
          },
          {
            key: 'loginStatus',
            title: '登录状态',
            width: '130px',
            type: 'textEnum',
            config: {
              constants: {
                success: '登录成功',
                failed: '登录失败',
              }
            }
          },
          {
            key: 'username',
            title: '登陆账号',
            width: '130px',
          },
          {
            key: 'orgName',
            title: '登陆机构',
            width: '130px',
          },
          {
            key: 'mark',
            title: '标记',
            width: '180px',
            ellipsis: true
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
    };
  },
  mounted() {
  },
  methods: {
  }

};
</script>

<style lang="less" scoped></style>